import {
  CatalogedProduct,
  CatalogRepositoryContract,
} from "@features/catalog/domain";
import { CheckoutRepositoryContract } from "@features/checkout/domain";
import { BaseRepository } from "@features/shared/data";
import { CATALOG_API } from "../constants";
import { mapCatalogedProduct } from "../mappers/cataloged-product.mapper";
import { CatalogedProductModel } from "../models/cataloged-product.model";
import { mergeProductPricingDetails } from "../utils";

export class CatalogRepository
  extends BaseRepository
  implements CatalogRepositoryContract
{
  public constructor(protected checkoutRepository: CheckoutRepositoryContract) {
    super();
  }

  public async list(isLocked: boolean): Promise<CatalogedProduct[]> {
    const response = await this.http.get<CatalogedProductModel[]>(
      CATALOG_API.GET_CATALOGED_PRODUCTS_URL(isLocked),
    );

    const { products: productsPricingDetails } =
      await this.checkoutRepository.getProductsPricing({
        products: response.data.map(product => ({
          productId: product.prodID,
          desiredPrice: product.customPrice,
          quantity: 1,
        })),
      });

    const products = response.data.map(mapCatalogedProduct);

    return mergeProductPricingDetails(products, productsPricingDetails);
  }

  public async remove(productId: string): Promise<void> {
    await this.http.patch(
      CATALOG_API.REMOVE_CATALOGED_PRODUCT_URL(productId),
      {},
    );
  }

  public async updateCustomPrice(
    productId: string,
    customPrice: number,
  ): Promise<void> {
    await this.http.patch(CATALOG_API.UPDATE_CUSTOM_PRICE_URL(productId), {
      price: customPrice,
    });
  }
}
